<template>
  <b-card-code id="user-history" class="view-access" no-body>

    <b-row>
      <b-col md="12">
        <div class="page-header">
          <b-row>
            <b-col class="filtersContainer" md="12">
              <div class="filters">

                <div class="filter-column">
                  <v-select
                      @input="getUsers"
                      placeholder="Անհասանելի է"
                      id="vue-select-car"
                      :reduce="prefix => prefix.id"
                      v-model="filters.api"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="apis"
                      class="companyFilterSelect"
                  />
                </div>

              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :items="statuses"
            :fields="fields"
        >
          <template #cell(createdAt)="data">
            {{ data.value | formatDate }}
          </template>

          <template #cell(dahk_status)="data">
            <b-badge class="status_badge" :variant="color(data.value)">
              <fa-icon :icon="icon(data.value)" />
            </b-badge>
            <span class="ml-2">{{ (data.value) ? 'Հասանելի է' : 'Հասանելի չէ' }}</span>
          </template>

          <template #cell(roadpolice_status)="data">
            <b-badge class="status_badge" :variant="color(data.value)">
              <fa-icon :icon="icon(data.value)" />
            </b-badge>
            <span class="ml-2">{{ (data.value) ? 'Հասանելի է' : 'Հասանելի չէ' }}</span>
          </template>

        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
              :label="'Գտնվել է ' + pagination.total + ' տող'"
              label-cols="3"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
          >
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
                @input="getUsers"
                v-model="pagination.current_page"
                :total-rows="pagination.total"
                :per-page="pagination.count"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-col>
    </b-row>

  </b-card-code>
</template>

<script>

import moduleApi from '@/store/api/moduleApi'
import store from '@/store'
import {
  BTable,
  BCardBody,
  BFormGroup,
  BPagination,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormCheckbox, BBadge
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode'
import vSelect from 'vue-select'

export default {
  name: 'accesses',
  components: {
    BCardCode,
    BCardBody,
    BTable,
    BFormGroup,
    BPagination,
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormCheckbox,
    BBadge,
    vSelect
  },
  data() {
    return {
      API_STORE_MODULE_NAME: 'apiModule',
      fields: [
        {
          key: 'createdAt',
          label: 'Ամսաթիվ/ժամ'
        },
        {
          key: 'dahk_status',
          label: 'ԴԱՀԿ'
        },
        {
          key: 'roadpolice_status',
          label: 'ՃՈ'
        }
      ],
      filters: {
        api: null
      },
      apis: [
        {
          id: 'dahk',
          label: 'Դահկ'
        },
        {
          id: 'road_police',
          label: 'ՃՈ'
        }
      ]
    }
  },
  computed: {
    users() {
      return this.$store.state[this.API_STORE_MODULE_NAME].users
    },
    pagination() {
      return this.$store.state[this.API_STORE_MODULE_NAME].pagination
    },
    statuses() {
      return this.$store.state[this.API_STORE_MODULE_NAME].api_statuses
    },
    color () {
      return (item) => {
        if(item === false) {
          return 'danger'
        } else if (item === true) {
          return 'success'
        } else {
          return 'warning'
        }
      }
    },
    icon() {
      return (item) => {
        if(item === false) {
          return 'times-circle'
        } else if (item === true) {
          return 'check-circle'
        } else {
          return 'info-circle'
        }
      }
    }
  },
  created() {
    if (!store.hasModule(this.API_STORE_MODULE_NAME)) store.registerModule(this.API_STORE_MODULE_NAME, moduleApi)
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$vs.loading()
      const filterData = {
        current_page: this.pagination.current_page,
        ...this.filters
      }
      this.$store.dispatch(`${this.API_STORE_MODULE_NAME}/getApiStatuses`, this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },
    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },
  }
}
</script>
<style lang="scss">
#user-history {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 16rem);
    min-height: calc(var(--vh, 1vh) * 100 - 16rem);
  }
}
.view-access table td, .view-access table th {
  font-size: 18px;
  font-weight: bold;
}
.status_badge {
  width: 150px;
}
</style>
